<template>
  <div id="app" v-if="finishedLoadingComponents">
    <b-alert :show="isAlertShowing && !logged" :fade="true" dismissible :variant="alertColor">{{ $i18n.t(alertMessage) }}</b-alert>
    <transition name="app-fade" mode="out-in">
      <auth v-if="!logged" @login="login" />

      <div v-else>
        <header v-show="!isFullscreen">

			<logo-headNav @logout="logout">
				<template #logo>
					<div class="logoAndVersionContainer"  @click="()=>{isHoveringLogo = false}" @mouseover="()=>{isHoveringLogo = true}" @mouseleave="()=>{isHoveringLogo = false}">
						<div class="logoWrapper">
							<b-img v-if="!isHoveringLogo" class="tmhub" center :src="require('./assets/tmHub_white.png')" fluid />
							<b-img v-else center class="mtwLogo" :src="require('./assets/mtw_white.png')" fluid />
						</div>
						<strong>v1.0.70.15</strong>
					</div>
				</template>
			</logo-headNav>

			<menu-sidebar @hideSideBar="hideSideBar()" @showSideBar="showSideBar()" />
        </header>
		
        <transition name="loading">
          <div id="main">
			<lost-connection-overlay :failedConnectionWithAxios="failedConnectionWithAxios" />
            <main :style="mainStyle">
				<transition name="router-fade" mode="out-in">
					<router-view @toggleFullscreen="toggleFullscreen" />
				</transition>
            </main>
          </div>
        </transition>

		<b-alert :show="isAlertShowing" :fade="true" dismissible :variant="alertColor" class="position-fixed fixed-bottom m-0 rounded-0 api-error">
			{{$i18n.t(alertMessage).replace("{target}", $i18n.t(errorOrigin))}}
		</b-alert>
		
		<comment-alarm-modal />

      </div>


    </transition>
  </div>
</template>
  
<script>
/* eslint-disable */
import Auth from './pages/Auth.vue'
import DashboardPanel from '../../UtilsTelemetry/components/dashboard/DashboardPanel/index.vue'
import MobileHome from './pages/Mobile.vue'
import Presentation from './pages/Presentation.vue'
import User from './pages/User.vue'
import ToastContent from '../../Utils/components/shared/misc/Toast.vue'
import LostConnectionOverlay from '../../UtilsTelemetry/components/shared/LostConnectionOverlay/index.vue'

export default {
	components: {
		'Auth': Auth,
		LostConnectionOverlay
	},
	data() {
		return {

			isStatusNotificationLoading: false,
			isAlarmNotificationLoading: false,


			//Exibição 

			finishedLoadingComponents: false,
			isHoveringLogo: false,
			isFullscreen: false,

			isSidebarOpen: false,
			mainStyle: {
				'margin-left': '0',
			},

			// Alertas
			isAlertShowing: false,
			alertMessage: "Sucesso",
			alertColor: "success",
			errorOrigin: '',

			failedConnectionWithAxios: false,

			// Autenticação
			id: -1,
			refresh: true,
			mobilePrivilege: ['Dashboard', 'Map', 'BlueprintMap'],

			mtwPrivilege: ['BlueprintMap', 'Servers', 'Dashboard','Group','Display','ColorConfig','Map','Device',
				'History','Variable','Schedule','Measurer','Alarm','AlarmConfiguration', 'VideoServerConfig', 'VideoConfig', 'Blueprint',
				'Action','Recipient','User','SerialNumber', 'RecipientGroup',  'Presentation', 'Profile', 'Audit', 'Layer', 'MapLayers', 
				'BlueprintLayers', 'HistoryEvents', 'Mosaic', 'Network', 'Report', 'Aggregation'],

			adminPrivilege: ['BlueprintMap', 'Dashboard','User','Group','Display','ColorConfig','Map','Device',
				'History','Variable','Schedule','Measurer','Alarm','AlarmConfiguration','VideoServerConfig', 'VideoConfig', 'Blueprint',
				'Action','Recipient', 'Presentation', 'RecipientGroup', 'Presentation', 'Profile', 'Layer', 'MapLayers', 
				'BlueprintLayers','HistoryEvents', 'Mosaic', 'Network', 'Report', 'Aggregation'],

			operatorPrivilege: ['BlueprintMap', 'Dashboard','Group','Display','ColorConfig','Map','Device', 'Blueprint',
				'History','Measurer','AlarmConfiguration', 'Presentation', 'RecipientGroup', 'Profile', 'Layer', 'MapLayers', 
				'BlueprintLayers', 'HistoryEvents', 'Report'],



		}
	},

	methods: {

		//  Autenticação 

		//Remove a animação de carregar no index.html e carrega o template do App.vue
		loadApp(){
			var preRenderSpinner = document.getElementById('pre-render-app_spinner')
			if(preRenderSpinner){
				preRenderSpinner.remove()
			}
			this.finishedLoadingComponents = true
		},

		async login(redirectToHomepage=true) {
			this.id = this.$session.get('logged_id');
			
			const url = this.$session.get("api_url")
			const servername = this.$apiController.GetServerName(url)

			const cookieExpireTime = this.$cookie.getExpireTime() / 60
			this.$cookie.setCookie("logged_id", this.id, cookieExpireTime)
			this.$cookie.setCookie("server_name", servername, cookieExpireTime)


			this.loadRoutes();
			if(redirectToHomepage){
				this.$router.push('/').catch(() => { });
				this.reloadDashboardRoutes()
			}else { // Se não for redirecionado para página inicial é necessário carregar todas as rotas de paineis do usuário
				await this.reloadDashboardRoutes()
			}
			this.refresh = !this.refresh;
			this.stopInterval()
			this.startInterval()
		},

		logout() {

			this.$session.set('logged_id', 0);
			this.clearCookies()

			this.refresh = !this.refresh;
			this.$router.options.routes = [];
			this.$router.options.routes.length = 0;
			this.$toast.clear();
			this.$router.replaceRoutes([])
			this.stopInterval()
			this.hideSideBar()
		},

		clearCookies(){
			this.$cookie.eraseCookie("logged_id")
			this.$cookie.eraseCookie("server_name")
		},


		// Rotas

		addRoute(name) {
			var route = '';
			if (name == "Map") route = { path: `/Map/:layerId?`, name: `Map`, component: () => import(`./pages/Map.vue`), title: `Map`, menu: false }
			if(name == "BlueprintMap") route = { path: `/blueprint/map/:blueprintId?`, name: `BlueprintMap`, component: () => import(`./pages/BlueprintMap.vue`), title: `BlueprintMap`, menu: false}
			else if (name != "") route = { path: `/${name}`, name: `${name}`, component: () => import(`./pages/${name}.vue`), title: `${name}`, menu: false };
			else {
				var homepage
				if (this.isMobile) { homepage = MobileHome }
				else if (this.$session.get('privilege') == '0' || this.$session.get('privilege') == '1') { 
					homepage = User
				}
				else { 
					homepage = Presentation
				}
				route = {
					path: `/`,
					name: 'home',
					component: homepage,
					title: 'home',
					menu: false,
					props: true,
				}
			}
			this.$router.addRoute(route);
			this.$router.options.routes.push(route);
		},

		loadRoutes() {
			this.$router.options.routes.length = 0;

			if (this.isMobile) this.mobilePrivilege.forEach(el => this.addRoute(el))
			else if (this.$session.get('privilege') == '0') this.mtwPrivilege.forEach(el => this.addRoute(el));
			else if (this.$session.get('privilege') == '1') this.adminPrivilege.forEach(el => this.addRoute(el));
			else if (this.$session.get('privilege') == '2') this.operatorPrivilege.forEach(el => this.addRoute(el));
			this.addRoute('');
			var dashboardRoute = {
				path: `/dashboard/:id?`,
				name: 'Dashboard',
				component: DashboardPanel,
				title: 'dashboardPanel',
				menu: true,
				props: true,
			}
			this.$router.addRoute(dashboardRoute);
			this.$router.options.routes.push(dashboardRoute);

			if (this.$session.get('privilege') == '0') return
			var route = {
				path: `/presentation/:begin`,
				name: 'presentation',
				params: { begin: 'disable' },
				component: Presentation,
				title: 'presentation',
				menu: true,
				props: true,
			}

			this.$router.addRoute(route);
			this.$router.options.routes.push(route);
		},

		reloadDashboardRoutes() {
			var user = this.$session.get('user')
			return this.dashboardService
				.listByUserId(this.id)
				.then(dashboardList => {
					user.dashboards = dashboardList
					this.$session.set("user", user)
				})
		},


		// Intervalos
		startInterval(){
			this.dashboardUpdateInterval = setInterval(this.reloadDashboardRoutes, 30000)
			this.notificationsInterval = setInterval(this.reloadNotifications, 1500)
		},

		stopInterval(){
			clearInterval(this.reloadDashboardRoutes)
			clearInterval(this.reloadNotifications)
		},


		// Notificações 
		// Notificação -- Evento 

		showToast(message) {
			if(!this.$enableToasts.getIsToastsEnabled()){
				this.$toast.clear();
				return
			}
			const router = this.$router
			const waitNextTick = async () => await this.$nextTick()

			const toast = message.isAlarm ?
				message.value == "Normal" ? this.$toast.success : this.$toast.error
				: message.value == "Online" ? this.$toast.success : this.$toast.warning

			const toastId = toast({
				component: ToastContent,
				props: { message: message, i18n: this.$i18n},
				listeners: {
					redirect: async (route) => {
						router.push('/dashboard').catch(() => { }); // se entrar no erro quer dizer que está no /dashboard
						await waitNextTick()
						router.push(route);
					},
					close: () => {
						this.$toast.dismiss(toastId)
					}
				},
			},
				{
					position: "bottom-left",
					timeout: 5000,
					closeOnClick: false,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: false,
					draggablePercent: 0.6,
					hideProgressBar: false,
					closeButton: false,
					showCloseButtonOnHover: true,
					icon: false,
					rtl: false,
				});
		},

		async reloadNotifications() {
			const userId = this.$session.get("logged_id")
			if(!this.isStatusNotificationLoading){

				this.isStatusNotificationLoading = true

				this.statusService.getLatestByUserId(userId).then(res => {
					Status.SetStatusResponse(res)
					this.isStatusNotificationLoading = false
				})
			}

			if(!this.isAlarmNotificationLoading){

				this.isAlarmNotificationLoading = true

				this.alarmDataService.getLatestByUserId(userId).then(alarms => {
					for(let alarm of alarms){
						let topic = `alarm/${alarm.id}`
						Alarm.UpdateAlarm(topic, alarm)
					}

					this.isAlarmNotificationLoading = false
				})
				
			}
			this.loadAlarmNotifications()
			this.loadStatusNotifications()
		},

		loadStatusNotifications(){
			// eslint-disable-next-line
			const statusMap = Status.GetStatusMap()
			statusMap.forEach(async (item) => {
				const topic = item.name
				const value = item.value
				if (!value.show || !value.statusNotification) return
				// eslint-disable-next-line
				Status.SetStatusShow(topic)
				const message = {
					isAlarm: false,
					value: value.value == '0' ? "Offline" : "Online",
					target: `${value.name} (${topic.split("/")[0]})`,
					time: value.datetime.split(" ")[1],
				}
				this.showToast(message)
			})
		
		},

		loadAlarmNotifications(){
			// eslint-disable-next-line
			const alarmMap = Alarm.GetAlarmMap()

			alarmMap.forEach(async item => {
				const { name, value } = item
				if (!value.show) return;
				// eslint-disable-next-line
				Alarm.SetAlarmShow(name)
				var listOfAlarmTypes = ["Normal", "Pre-Ativo", "Ativo"]
				var alarmType = listOfAlarmTypes[parseInt(value.value)]

				var affectedDashboards = []
				var affectedLayers=[]

				const message = {
					isAlarm: true,
					value: alarmType,
					id: parseInt(value.id),
					idDb: value.idDb,
					target: value.name,
					time: value.datetime.split(" ")[1],
					dashboards: affectedDashboards,
					layers: affectedLayers,
				}
				this.showToast(message)
			})

		},


		// Notificação -- API
		showAlert() {
			this.isAlertShowing = true
			setTimeout(() => {
				this.isAlertShowing = false
			}, 10000)
		},


		// Exibição

		// Barra lateral
		showSideBar() {
			var margin = window.innerWidth <= 991 ? "0" : "15rem"
			this.isSidebarOpen = true
			this.mainStyle = {
				transition: '0.5s',
				'margin-left': margin,
			}
		},

		hideSideBar() {
			this.isSidebarOpen = false
			this.mainStyle = {
				transition: '0.5s',
				'margin-left': '0',
			}
		},

		// Tela cheia
		toggleFullscreen(state) {
			this.isFullscreen = state
			this.$root.$emit('bv::toggle::collapse', 'sidebar')
			this.hideSideBar()
		},


	},

	computed: {
		logged() {
			this.refresh;
			return this.$session.get('logged_id') > 0;
		},
	},

	async created() {
		this.$session.start();
		this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		this.$session.set('isMobile', this.isMobile)
		this.userService = new this.$userService();
		this.dashboardService = new this.$dashboardService();
		this.variableService = new this.$variableService();
		this.alarmService = new this.$alarmService();
		this.statusService = new this.$statusService();
		this.alarmDataService  = new this.$alarmDataService();

		this.id = this.$session.get('logged_id');

		this.finishedLoadingComponents = false

		if (this.id > 0) {
			var api_url = this.$session.get("api_url")
			if(api_url != undefined){
				this.$apiController.ChangeApiURL(api_url)
			}
			this.loadRoutes();
			this.reloadDashboardRoutes();
			this.startInterval()
		}
		else {

			const cookieSessionUserId = this.$cookie.getCookie("logged_id")
			const serverName = this.$cookie.getCookie("server_name")
			const serverUrl = this.$apiController.GetServerURL(serverName)

			if(cookieSessionUserId != null && serverUrl != undefined){
				
				this.$session.set("api_url", serverUrl)
				this.$apiController.ChangeApiURL(serverUrl)

				await this.userService
				.search(cookieSessionUserId)
				.then(async (user) => {
					if(user.enable && user.id > 0){
						this.$session.set('logged_id', user.id);
                        this.$session.set('user', user);
                        this.$session.set('privilege', user.privilege);
						await this.login(false)
					}else {
						this.clearCookies()
					}
				}).catch(e => {
					console.error(e)
					this.clearCookies()
				})
			}

		}

		this.loadApp()

		this.$apiInterceptor.InterceptRequest(this)
		this.$apiInterceptor.InterceptResponse(this)
	},
}

</script>
  
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css";
@import "https://site-assets.fontawesome.com/releases/v6.2.1/css/all.css";

.app-fade-enter-active,
.app-fade-leave-active {
	transition: all 1s;
}

.app-fade-enter,
.app-fade-leave-to {
	opacity: 0;
}


.loading-enter-active,
.loading-leave-active {
	transition: all .5s;
}

.loading-enter,
.loading-leave-to {
	opacity: 0;
}


.router-fade-enter-active,
.router-fade-leave-active {
	transition: all .5s;
}

.router-fade-enter,
.router-fade-leave-to {
	opacity: 0;
}


header {
	position: relative;
	z-index: 101;
}

#app {
	min-height: 100vh;
	font-family: "Chakra Petch", 'Avenir', Helvetica, Arial, sans-serif;
	color: white;
	/* text-align: center; */
	letter-spacing: 0.05rem;
	// overflow-x: hidden;
	opacity: 0.85;
	&:has(#lostConnectionMessage) { //Quando o overlay de conexão perdida estiver ativo 
		max-height: 100vh;
		overflow: hidden;
	}
}


.logoAndVersionContainer{
	display: flex;
	align-items: flex-end;
	gap: 0.25rem;
	.logoWrapper{
		width: 150px;
	}
	strong{
		color: white;
		margin-bottom: 0rem;
		font-size: 0.66rem;
		opacity: 0.55;
		user-select: none;
	}
}

.mtwLogo{
	width: 120px;
	user-select: none;
	pointer-events: none;
}
a.router-link-active {
	color: #e08f0e !important;
	opacity: 1 !important;
	text-shadow: 0px 0px 6px rgba(224, 144, 15, 1);
}

body {
	height: 100%;
	background-color: #273136;
	background-image: linear-gradient(180deg, rgba(50, 70, 80, .9) 0, #0d101b 100%);
	font-family: "Chakra Petch, sans-serif";

	--background-image: url("/src/assets/bg.jpg");
}

body::before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	z-index: -1;
	/* background-image: linear-gradient(180deg,rgba(50,70,80,.9) 0,#0d101b 100%); */
	background-image: url("/src/assets/pattern.png");
	background-repeat: repeat;
	background-size: 80px 80px;
}

body::after {
	content: "";
	position: fixed;
	top: 0;
	height: 100%;
	left: 0;
	right: 0;
	z-index: -5;
	background-color: #273136;
	background-image: linear-gradient(180deg, rgba(var(--tmHubTheme-primary-color), 0.9) 0, #0d101b 100%);
	background-image: linear-gradient(180deg, rgba(var(--tmHubTheme-primary-color), .9) 0, #0d101b 100%), var(--background-image);
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: initial;
	height: 100%;
	transition: background .2s linear;
	background-size: cover;
}

.vue-grid-item.vue-resizable.cssTransforms {
	touch-action: none;
}

body {
	background-color: transparent;
}

@mixin borderInCorners {
	background:
		linear-gradient(to right, white 4px, transparent 4px) 0 0,
		linear-gradient(to right, white 4px, transparent 4px) 0 100%,
		linear-gradient(to left, white 4px, transparent 4px) 100% 0,
		linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
		linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
		linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
		linear-gradient(to top, white 4px, transparent 4px) 0 100%,
		linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
}

@mixin containerHeadTable {
	/* background-color: red; */
	width: 100vw;
	height: 100%;
	margin: 0 5rem;
	padding: 2rem 1rem;

	hr {
		width: 100%;
		margin: 0;
		padding: 0;
		background: #acacac;
		height: 2px;
		margin-bottom: 2rem;
	}

	tbody {
		color: white;
	}
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #e08f0e;
	border-radius: 10px;
}

::-webkit-scrollbar-track-piece {
	background: #1a283a;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #e08f0e;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #a06300;
}

.btn-success{
	color: rgb(var(--tmHubTheme-success-color))!important;
	border-color: rgb(var(--tmHubTheme-success-color))!important;
}
.btn-outline-success{
	color: rgb(var(--tmHubTheme-success-color))!important;
	border-color: rgb(var(--tmHubTheme-success-color))!important;
	&:hover{
		background-color: rgb(var(--tmHubTheme-success-color))!important;
		color: white!important;
	}
}

.panel-link {
	text-decoration: underline;
	font-weight: 900;
	cursor: pointer;
	color: white;
}

.api-error.alert-error{
    border: 2px solid #f96d6d ;
    background: #bc5656;
}
.alert-success.api-error{
    border: 2px solid #187f1e;
    background: #53ba41;
    font-weight: bold;
    color: white;
}


</style>
  
